export const VOTE_HREF =
  'https://rating.hh.ru/poll/?utm_source=vtb_hh&utm_medium=referral&utm_campaign=special_project';
export const VACANCIES_HREF =
  'https://hh.ru/employer/4181?utm_source=vtb_hh&utm_medium=referral&utm_campaign=special_project_vacancy';

export type TStepButton = {
  text: string;
  nextStep: string;
  analyticLabel: string;
  skill?: string;
};

type TStep = {
  videoName: string;
  buttons?: TStepButton[];
  isLast?: boolean;
  analyticAction: string;
};

type TSteps = Record<string, TStep>;

const DEPARTMENT_BRANCH_6_MONTHS_FORWARD = {
  department_forward_6_months: {
    videoName: 'department/1_2_department_forward_6_months',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Спросить, где\u00A0её родители?',
        nextStep: 'department_ask_parents',
        analyticLabel: 'choice1',
      },
      {
        text: 'Предложить проследовать к\u00A0рабочему месту',
        nextStep: 'department_go_to_place',
        analyticLabel: 'choice1',
      },
    ],
  },

  department_ask_parents: {
    videoName: 'department/1_3_department_ask_parents',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Предложить воды',
        nextStep: 'department_offer_water',
        analyticLabel: 'choice2',
      },
      {
        text: 'Спросить, какая помощь требуется',
        nextStep: 'department_ask_to_help',
        analyticLabel: 'choice2',
      },
    ],
  },

  department_go_to_place: {
    videoName: 'department/1_3_department_go_to_place',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Предложить воды',
        nextStep: 'department_offer_water',
        analyticLabel: 'choice2',
      },
      {
        text: 'Спросить, какая помощь требуется',
        nextStep: 'department_ask_to_help',
        analyticLabel: 'choice2',
      },
    ],
  },

  department_offer_water: {
    videoName: 'department/1_4_department_offer_water',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Спасибо!',
        nextStep: 'department_ask_to_help',
        analyticLabel: 'choice3',
      },
      {
        text: 'Постараюсь справиться!',
        nextStep: 'department_ask_to_help',
        analyticLabel: 'choice3',
      },
    ],
  },

  department_ask_to_help: {
    videoName: 'department/1_4_department_ask_to_help',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Предложить оформить карту',
        nextStep: 'department_offer_card',
        analyticLabel: 'choice4',
      },
      {
        text: 'Посоветоваться с\u00A0руководителем',
        nextStep: 'department_consult_manager',
        analyticLabel: 'choice4',
      },
    ],
  },

  department_offer_card: {
    videoName: 'department/1_5_department_offer_card',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Рассказать о\u00A0программах лояльности для\u00A0путешественников',
        nextStep: 'department_programs_for_travelers',
        analyticLabel: 'choice5_1',
        skill: 'sales',
      },
      {
        text: 'Предложить уникальный дизайн карты',
        nextStep: 'department_unique_design',
        analyticLabel: 'choice5_1',
        skill: 'proactive',
      },
    ],
  },

  department_consult_manager: {
    videoName: 'department/1_7_department_consult_manager',
    analyticAction: 'bank6months',
    buttons: [
      {
        text: 'Справиться самостоятельно',
        nextStep: 'department_programs_for_travelers',
        analyticLabel: 'choice5_2',
        skill: 'adaptive',
      },
      {
        text: 'Попросить Оксану о\u00A0помощи',
        nextStep: 'department_ask_for_help',
        analyticLabel: 'choice5_2',
        skill: 'trainable',
      },
    ],
  },

  department_programs_for_travelers: {
    videoName: 'department/1_6_department_programs_for_travelers',
    analyticAction: 'bank6months',
    analyticLabel: '',
    isLast: true,
  },

  department_unique_design: {
    videoName: 'department/1_6_department_unique_design',
    analyticAction: 'bank6months',
    analyticLabel: '',
    isLast: true,
  },

  department_ask_for_help: {
    videoName: 'department/1_8_department_ask_for_help',
    analyticAction: 'bank6months',
    analyticLabel: '',
    isLast: true,
  },
};

const DEPARTMENT_BRANCH_2_YEARS_FORWARD = {
  department_forward_2_years: {
    videoName: 'department/2_2_department_forward_2_years',
    analyticAction: 'bank2year',
    buttons: [
      {
        text: 'Попрощаться с\u00A0клиентом',
        nextStep: 'department_goodbye',
        analyticLabel: 'choice1',
      },
      {
        text: 'Продолжить беседу с\u00A0клиентом',
        nextStep: 'department_goodbye',
        analyticLabel: 'choice1',
      },
    ],
  },

  department_goodbye: {
    videoName: 'department/2_3_department_goodbye',
    analyticAction: 'bank2year',
    buttons: [
      {
        text: 'Согласиться на\u00A0звонок',
        nextStep: 'department_agree_call',
        analyticLabel: 'choice2',
        skill: 'empathetic',
      },
      {
        text: 'Попросить звонить в\u00A0рабочее время',
        nextStep: 'department_call_in_worktime',
        analyticLabel: 'choice2',
        skill: 'personalBoundaries',
      },
    ],
  },

  department_agree_call: {
    videoName: 'department/2_4_department_agree_call',
    analyticAction: 'bank2year',
    buttons: [
      {
        text: 'Идем домой',
        nextStep: 'department_go_next',
        analyticLabel: 'choice3',
      },
      {
        text: 'Идём болеть за\u00A0коллег',
        nextStep: 'department_go_next',
        analyticLabel: 'choice3',
      },
    ],
  },

  department_call_in_worktime: {
    videoName: 'department/2_4_department_call_in_worktime',
    analyticAction: 'bank2year',
    buttons: [
      {
        text: 'Идём домой',
        nextStep: 'department_go_next',
        analyticLabel: 'choice3',
      },
      {
        text: 'Идём болеть за\u00A0коллег',
        nextStep: 'department_go_next',
        analyticLabel: 'choice3',
      },
    ],
  },

  department_go_next: {
    videoName: 'department/2_5_department_go_next',
    analyticAction: 'bank2year',
    buttons: [
      {
        text: 'Оставить на\u00A0посту охраны',
        nextStep: 'department_leave_post',
        analyticLabel: 'choice4',
        skill: 'responsible',
      },
      {
        text: 'Связаться с\u00A0клиентом',
        nextStep: 'department_call_client',
        analyticLabel: 'choice4',
        skill: 'operational',
      },
    ],
  },

  department_leave_post: {
    videoName: 'department/2_6_department_leave_post',
    analyticAction: 'bank2year',
    analyticLabel: '',
    isLast: true,
  },

  department_call_client: {
    videoName: 'department/2_6_department_call_client',
    analyticAction: 'bank2year',
    analyticLabel: '',
    isLast: true,
  },
};

const DEPARTMENT_BRANCH_100_YEARS_FORWARD = {
  department_forward_100_years: {
    videoName: 'department/3_2_department_forward_100_years',
    analyticAction: 'bank100year',
    buttons: [
      {
        text: 'Выкладывай, в\u00A0чём дело',
        nextStep: 'department_try_help',
        analyticLabel: 'choice1',
      },
      {
        text: 'Попробую…',
        nextStep: 'department_try_help',
        analyticLabel: 'choice1',
      },
    ],
  },

  department_try_help: {
    videoName: 'department/3_3_department_try_help',
    analyticAction: 'bank100year',
    buttons: [
      {
        text: 'Задай уточняющий вопрос',
        nextStep: 'department_clarifying_question',
        analyticLabel: 'choice2',
      },
      {
        text: 'Запроси видео оплаты',
        nextStep: 'department_payment_video',
        analyticLabel: 'choice2',
      },
    ],
  },

  department_clarifying_question: {
    videoName: 'department/3_4_department_clarifying_question',
    analyticAction: 'bank100year',
    buttons: [
      {
        text: 'Подключи тройную верификацию мыслей',
        nextStep: 'department_verify_thoughts',
        analyticLabel: 'choice3',
        skill: 'expertise',
      },
      {
        text: 'Отмени списание',
        nextStep: 'department_cancel_payment',
        analyticLabel: 'choice3',
        skill: 'quickDecision',
      },
    ],
  },

  department_payment_video: {
    videoName: 'department/3_4_department_payment_video',
    analyticAction: 'bank100year',
    buttons: [
      {
        text: 'Подключи тройную верификацию мыслей',
        nextStep: 'department_verify_thoughts',
        analyticLabel: 'choice3',
        skill: 'expertise',
      },
      {
        text: 'Объясни правила пользования сервисом повторно',
        nextStep: 'department_explain_rules',
        analyticLabel: 'choice3',
        skill: 'loyal',
      },
    ],
  },

  department_cancel_payment: {
    videoName: 'department/3_5_department_cancel_payment',
    analyticAction: 'bank100year',
    buttons: [
      {
        text: 'Подключи тройную верификацию мыслей',
        nextStep: 'department_verify_thoughts',
        analyticLabel: 'choice4',
        skill: 'expertise',
      },
      {
        text: 'Объясни правила пользования сервисом повторно',
        nextStep: 'department_explain_rules',
        analyticLabel: 'choice4',
        skill: 'loyal',
      },
    ],
  },

  department_explain_rules: {
    videoName: 'department/3_6_department_explain_rules',
    analyticAction: 'bank100year',
    analyticLabel: '',
    isLast: true,
  },

  department_verify_thoughts: {
    videoName: 'department/3_5_department_verify_thoughts',
    analyticAction: 'bank100year',
    analyticLabel: '',
    isLast: true,
  },
};

const DEPARTMENT_BRANCH = {
  department_start: {
    videoName: 'department/0_1_department_start',
    analyticAction: 'bank',
    buttons: [
      {
        text: 'Вперёд на\u00A06\u00A0месяцев',
        nextStep: 'department_forward_6_months',
        analyticLabel: '6months',
      },
      {
        text: 'Вперёд на\u00A02\u00A0года',
        nextStep: 'department_forward_2_years',
        analyticLabel: '2years',
      },
      {
        text: 'Вперёд на\u00A0100\u00A0лет',
        nextStep: 'department_forward_100_years',
        analyticLabel: '100years',
      },
    ],
  },

  ...DEPARTMENT_BRANCH_6_MONTHS_FORWARD,

  ...DEPARTMENT_BRANCH_2_YEARS_FORWARD,

  ...DEPARTMENT_BRANCH_100_YEARS_FORWARD,
};

const OFFICE_BRANCH_6_MONTHS_FORWARD = {
  office_forward_6_months: {
    videoName: 'office/1_2_office_forward_6_months',
    analyticAction: 'office6months',
    buttons: [
      {
        text: 'Помочь коллеге',
        nextStep: 'office_help_recheck',
        analyticLabel: 'choice1',
      },
      {
        text: 'Предложить всё перепроверить',
        nextStep: 'office_help_recheck',
        analyticLabel: 'choice1',
      },
    ],
  },

  office_help_recheck: {
    videoName: 'office/1_3_office_help_recheck',
    analyticAction: 'office6months',
    buttons: [
      {
        text: 'Проверь, вся ли база данных подтянулась…',
        nextStep: 'office_check_database',
        analyticLabel: 'choice2',
        skill: 'attention',
      },
      {
        text: 'Посоветуйся с\u00A0руководителем',
        nextStep: 'office_council_manager',
        analyticLabel: 'choice2',
        skill: 'judicious',
      },
    ],
  },

  office_check_database: {
    videoName: 'office/1_4_office_check_database',
    analyticAction: 'office6months',
    buttons: [
      {
        text: 'Не пропустим!',
        nextStep: 'office_announcement',
        analyticLabel: 'choice3',
      },
      {
        text: 'Придём с\u00A0подарком',
        nextStep: 'office_announcement',
        analyticLabel: 'choice3',
      },
    ],
  },

  office_council_manager: {
    videoName: 'office/1_4_office_council_manager',
    analyticAction: 'office6months',
    buttons: [
      {
        text: 'Не пропустим!',
        nextStep: 'office_announcement',
        analyticLabel: 'choice3',
      },
      {
        text: 'Придём с\u00A0подарком',
        nextStep: 'office_announcement',
        analyticLabel: 'choice3',
      },
    ],
  },

  office_announcement: {
    videoName: 'office/1_5_office_announcement',
    analyticAction: 'office6months',
    buttons: [
      {
        text: 'А может на\u00A0Алтае?',
        nextStep: 'office_humorist',
        analyticLabel: 'choice4',
        skill: 'initiative',
      },
      {
        text: 'Спасибо, но я люблю только сплавы из\u00A0драгметаллов',
        nextStep: 'office_humorist',
        analyticLabel: 'choice4',
        skill: 'humor',
      },
    ],
  },

  office_humorist: {
    videoName: 'office/1_6_office_humorist',
    analyticAction: 'office6months',
    analyticLabel: '',
    isLast: true,
  },
};

const OFFICE_BRANCH_2_YEARS_FORWARD = {
  office_forward_2_years: {
    videoName: 'office/2_1_office_forward_2_years',
    analyticAction: 'office2years',
    buttons: [
      {
        text: 'Давайте сначала поедим',
        nextStep: 'office_discuss_tasks',
        analyticLabel: 'choice1',
      },
      {
        text: 'Говори, если не\u00A0терпится',
        nextStep: 'office_discuss_tasks',
        analyticLabel: 'choice1',
      },
    ],
  },

  office_discuss_tasks: {
    videoName: 'office/2_2_office_discuss_tasks',
    analyticAction: 'office2years',
    buttons: [
      {
        text: 'Согласиться',
        nextStep: 'office_agree',
        analyticLabel: 'choice2',
        skill: 'experimenter',
      },
      {
        text: 'Спросить мнение Татьяны',
        nextStep: 'office_ask_tatyana',
        analyticLabel: 'choice2',
        skill: 'diplomat',
      },
    ],
  },

  office_agree: {
    videoName: 'office/2_3_office_agree',
    analyticAction: 'office2years',
    buttons: [
      {
        text: 'Поблагодарить',
        nextStep: 'office_give_thanks',
        analyticLabel: 'choice3',
      },
      {
        text: 'Пригласить за\u00A0стол',
        nextStep: 'office_give_thanks',
        analyticLabel: 'choice3',
      },
    ],
  },

  office_ask_tatyana: {
    videoName: 'office/2_3_office_ask_tatyana',
    analyticAction: 'office2years',
    buttons: [
      {
        text: 'Поблагодарить',
        nextStep: 'office_give_thanks',
        analyticLabel: 'choice3',
      },
      {
        text: 'Пригласить за\u00A0стол',
        nextStep: 'office_give_thanks',
        analyticLabel: 'choice3',
      },
    ],
  },

  office_give_thanks: {
    videoName: 'office/2_4_office_give_thanks',
    analyticAction: 'office2years',
    buttons: [
      {
        text: 'Все дело в\u00A0команде, но\u00A0без грамотного руководства никуда!',
        nextStep: 'office_good_team',
        analyticLabel: 'choice4',
        skill: 'ambitious',
      },
      {
        text: 'Руководитель\u00A0– лишь часть крутой команды!',
        nextStep: 'office_good_team',
        analyticLabel: 'choice4',
        skill: 'teamwork',
      },
    ],
  },

  office_good_team: {
    videoName: 'office/2_5_office_good_team',
    analyticAction: 'office2years',
    analyticLabel: '',
    isLast: true,
  },
};

const OFFICE_BRANCH_100_YEARS_FORWARD = {
  office_forward_100_years: {
    videoName: 'office/3_1_office_forward_100_years',
    analyticAction: 'office100years',
    buttons: [
      {
        text: 'Запросить мнение аналитика',
        nextStep: 'office_get_analytic',
        analyticLabel: 'choice1',
        skill: 'management',
      },
      {
        text: 'Запустить исследование',
        nextStep: 'office_start_research',
        analyticLabel: 'choice1',
        skill: 'systematic',
      },
    ],
  },

  office_start_research: {
    videoName: 'office/3_2_office_start_research',
    analyticAction: 'office100years',
    buttons: [
      {
        text: 'Назначить новую встречу',
        nextStep: 'office_new_meet',
        analyticLabel: 'choice2',
      },
      {
        text: 'Продолжить текущую встречу',
        nextStep: 'office_continue_meet',
        analyticLabel: 'choice2',
      },
    ],
  },

  office_get_analytic: {
    videoName: 'office/3_2_office_get_analytic',
    analyticAction: 'office100years',
    buttons: [
      {
        text: 'Назначить новую встречу',
        nextStep: 'office_new_meet',
        analyticLabel: 'choice2',
      },
      {
        text: 'Продолжить текущую встречу',
        nextStep: 'office_continue_meet',
        analyticLabel: 'choice2',
      },
    ],
  },

  office_new_meet: {
    videoName: 'office/3_3_office_new_meet',
    analyticAction: 'office100years',
    buttons: [
      {
        text: 'Одобрить решение',
        nextStep: 'office_approve',
        analyticLabel: 'choice3_1',
        skill: 'emotional',
      },
      {
        text: 'Продумать передачу дел',
        nextStep: 'office_consider',
        analyticLabel: 'choice3_1',
        skill: 'focus',
      },
    ],
  },

  office_continue_meet: {
    videoName: 'office/3_3_office_continue_meet',
    analyticAction: 'office100years',
    buttons: [
      {
        text: 'Поздравить и\u00A0одобрить решение',
        nextStep: 'office_approve_karina',
        analyticLabel: 'choice3_2',
        skill: 'emotional',
      },
      {
        text: 'Продумать передачу дел',
        nextStep: 'office_consider_karina',
        analyticLabel: 'choice3_2',
        skill: 'focus',
      },
    ],
  },

  office_approve: {
    videoName: 'office/3_4_office_approve',
    analyticAction: 'office100years',
    analyticLabel: '',
    isLast: true,
  },

  office_consider: {
    videoName: 'office/3_4_office_consider',
    analyticAction: 'office100years',
    analyticLabel: '',
    isLast: true,
  },

  office_approve_karina: {
    videoName: 'office/3_4_office_approve_karina',
    analyticAction: 'office100years',
    analyticLabel: '',
    isLast: true,
  },

  office_consider_karina: {
    videoName: 'office/3_4_office_consider_karina',
    analyticAction: 'office100years',
    analyticLabel: '',
    isLast: true,
  },
};

const OFFICE_BRANCH = {
  office_start: {
    videoName: 'office/0_1_office_start',
    analyticAction: 'office',
    buttons: [
      {
        text: 'Вперёд на\u00A06\u00A0месяцев',
        nextStep: 'office_forward_6_months',
        analyticLabel: '6months',
      },
      {
        text: 'Вперёд на\u00A02\u00A0года',
        nextStep: 'office_forward_2_years',
        analyticLabel: '2years',
      },
      {
        text: 'Вперёд на\u00A0100\u00A0лет',
        nextStep: 'office_forward_100_years',
        analyticLabel: '100years',
      },
    ],
  },

  ...OFFICE_BRANCH_6_MONTHS_FORWARD,

  ...OFFICE_BRANCH_2_YEARS_FORWARD,

  ...OFFICE_BRANCH_100_YEARS_FORWARD,
};

export const GAME_STEPS: TSteps = {
  ...DEPARTMENT_BRANCH,

  ...OFFICE_BRANCH,
};

export const RESULT_SKILLS = {
  sales: {
    title: 'Навыки продаж',
    text: 'Ты твёрдо знаешь, как улучшить продажи, и\u00A0да\u00A0– мы бы точно купили у\u00A0тебя ручку!',
  },
  proactive: {
    title: 'Проактивный',
    text: 'Ты отлично чувствуешь меняющиеся тенденции и\u00A0действуешь на\u00A0опережение.',
  },
  adaptive: {
    title: 'Адаптивный',
    text: 'Ты умеешь адаптироваться к\u00A0меняющимся обстоятельствам.',
  },
  trainable: {
    title: 'Обучаемый',
    text: 'Ты любишь учиться и\u00A0узнавать новое, и\u00A0это отлично сказывается на\u00A0твоих навыках.',
  },
  empathetic: {
    title: 'Эмпатичный',
    text: 'Ты умеешь ставить себя на\u00A0место другого человека, и\u00A0это помогает тебе понять любого.',
  },
  personalBoundaries: {
    title: 'Чёткие личные границы',
    text: 'Ты ценишь свои личные границы и\u00A0умеешь тактично, но\u00A0твердо их отстаивать.',
  },
  responsible: {
    title: 'Ответственный',
    text: 'Ты никогда не\u00A0подведёшь тех, кто на\u00A0тебя рассчитывает.',
  },
  operational: {
    title: 'Оперативный',
    text: 'Ты умеешь правильно и\u00A0быстро решить любую задачу и\u00A0никогда не\u00A0затягиваешь со\u00A0сроками.',
  },
  expertise: {
    title: 'Высокий уровень экспертизы',
    text: 'Твой опыт и\u00A0накопленные знания помогают тебе справляться с\u00A0любыми поступающими задачами.',
  },
  quickDecision: {
    title: 'Быстрое принятие решений',
    text: 'Ты знаешь цену времени и\u00A0предпочитаешь решать важные вопросы оперативно.',
  },
  loyal: {
    title: 'Лояльный',
    text: 'Ты ценишь человеческое отношение и\u00A0умеешь находить компромиссы.',
  },
  consistent: {
    title: 'Последовательный',
    text: 'Ты умеешь расставлять приоритеты в\u00A0задачах и\u00A0последовательно их решать.',
  },
  attention: {
    title: 'Внимание к\u00A0деталям',
    text: 'Ты знаешь, что именно детали помогают всей картине выглядеть целостно, и\u00A0никогда не\u00A0упускаешь их из\u00A0виду.',
  },
  judicious: {
    title: 'Рассудительный',
    text: 'Ты предпочитаешь действовать спокойно и\u00A0со\u00A0знанием дела.',
  },
  initiative: {
    title: 'Инициативный',
    text: 'Ты стремишься проявлять активность в\u00A0своих действиях и\u00A0умеешь делать первый шаг.',
  },
  humor: {
    title: 'Чувство юмора',
    text: 'Ты знаешь, как снизить градус напряжения и\u00A0создать дружелюбную атмосферу.',
  },
  experimenter: {
    title: 'Экспериментатор',
    text: 'Ты не боишься пробовать новые методики и\u00A0нестандартно подходишь к\u00A0решению вопросов.',
  },
  diplomat: {
    title: 'Дипломат',
    text: 'Ты умеешь договариваться и\u00A0выстраивать отличные деловые отношения.',
  },
  ambitious: {
    title: 'Амбициозный',
    text: 'Ты знаешь, чего хочешь, и\u00A0идешь к\u00A0поставленной цели.',
  },
  teamwork: {
    title: 'Навык работы в\u00A0команде',
    text: 'Ты умеешь работать в\u00A0команде, и\u00A0это точно оценят коллеги и\u00A0руководство.',
  },
  management: {
    title: 'Управленческий опыт',
    text: 'Ты умеешь принимать важные решения и\u00A0брать ответственность за\u00A0свою команду.',
  },
  systematic: {
    title: 'Системный подход',
    text: 'Ты знаешь, как связаны все элементы в\u00A0твоей работе, и\u00A0всегда стремишься к\u00A0их гармоничному взаимодействию.',
  },
  emotional: {
    title: 'Высокий эмоциональный интеллект',
    text: 'Ты умеешь распознавать эмоции, понимать намерения других людей и\u00A0их мотивацию.',
  },
  focus: {
    title: 'Фокус на\u00A0результате',
    text: 'Ты уверен: главное\u00A0– это результат, и\u00A0прилагаешь все силы для его достижения.',
  },
};
