import cn from 'classnames';
import { useAppDispatch } from '@/src/hooks';
import {
  TBranch,
  setBranch,
  setCurrentStep,
} from '@/src/store/slices/gameSlice';
import sendEventToCounters from '@/src/counterEvents';
import styles from './VideoCard.module.scss';
import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';

type TProps = {
  title: string;
  videoName: string;
  classes?: string;
  branch: TBranch;
  line: string;
};

export const VideoCard = ({
  title,
  videoName,
  classes,
  branch,
  line,
}: TProps) => {
  const dispatch = useAppDispatch();
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'line', label });
  };

  const handleSelectCard = () => {
    dispatch(setBranch(branch));
    dispatch(setCurrentStep(branch + '_start'));

    sendAnalytics(line);

    changePage('game');
  };

  return (
    <div className={cn(classes, styles.card)} onClick={handleSelectCard}>
      <div className={styles.content}>
        <div className={styles.video}>
          <img src={`./videos/intro/posters/${videoName}.jpg`} alt="" />
        </div>
        <p className={cn(styles.title, 'title_1')}>{title}</p>
      </div>
    </div>
  );
};
