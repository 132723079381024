/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePageContext } from '@/src/components/App/PageContext';
import { GAME_STEPS } from '@/src/constants';
import { Choice } from '@/src/components/Choice';
import { RotateScreen } from '@/src/components/RotateScreen/RotateScreen';
import { noop } from '@/src/utils/noop';
import styles from './Game.module.scss';
import sendEventToCounters from '@/src/counterEvents';
import soundSrc from './sound.mp3';
import { useAppSelector } from '@/src/hooks';
import {
  currentGameBranchSelector,
  currentGameCurrentStepSelector,
} from '@/src/store/selectors/game';
import ReactPlayer from 'react-player';
import { Loader } from '@/src/components/Loader';
import { isMobile } from 'react-device-detect';
import { useMobileOrientation } from 'react-device-detect';
import { Helmet } from 'react-helmet-async';

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const currentStep = useAppSelector(currentGameCurrentStepSelector);
  const branch = useAppSelector(currentGameBranchSelector) || 'department';

  const [isVisibleChoice, setIsVisibleChoice] = useState(false);

  const [isShowLoading, setIsShowLoading] = useState(false);
  const [duration, setDuration] = useState(0);

  const videoRef = useRef<ReactPlayer | null>(null);

  const { isLandscape } = useMobileOrientation();

  const currentVideoName = useMemo(
    () => GAME_STEPS[currentStep].videoName,
    [currentStep],
  );
  const currentButtons = useMemo(
    () => GAME_STEPS[currentStep].buttons,
    [currentStep],
  );
  const isLastVideo = useMemo(
    () => GAME_STEPS[currentStep].isLast,
    [currentStep],
  );
  const isNeedRollPhone = useMemo(
    () => !isLandscape && isMobile,
    [isLandscape, isMobile],
  );

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const sendAnalytics = (action: string, label: string) => {
    sendEventToCounters({ action, label });
  };

  useEffect(() => {
    const audio = audioRef.current;

    if (isNeedRollPhone) {
      if (audio) {
        const isPlaying =
          audio.currentTime > 0 &&
          !audio.paused &&
          !audio.ended &&
          audio.readyState > audio.HAVE_CURRENT_DATA;
        if (isPlaying) {
          audio.pause();
          audio.currentTime = 0;
        }
      }
    } else {
      if (isVisibleChoice && audio) {
        if (!isLastVideo) {
          audio.play();
        }
      }
    }
  }, [isNeedRollPhone]);

  useEffect(() => {
    const audio = audioRef.current;

    if (audio) {
      if (isVisibleChoice) {
        if (!isLastVideo) {
          audio.play();
        }
      } else {
        const isPlaying =
          audio.currentTime > 0 &&
          !audio.paused &&
          !audio.ended &&
          audio.readyState > audio.HAVE_CURRENT_DATA;
        if (isPlaying) {
          audio.pause();
          audio.currentTime = 0;
        }
      }
    }
  }, [isVisibleChoice]);

  return (
    <div className={styles.game}>
      <Helmet>
        {currentButtons?.map((button) => {
          return (
            <link
              key={`${button.text}_${button.nextStep}`}
              rel="preload"
              as="image"
              href={`./posters/${GAME_STEPS[button.nextStep].videoName}.jpg`}
            />
          );
        })}
      </Helmet>

      {isNeedRollPhone && <RotateScreen isOpen={isNeedRollPhone} />}

      <div className={styles.game_wrapper}>
        {currentVideoName && (
          <>
            <div className={styles.poster}>
              <img
                key={currentVideoName}
                src={`./posters/${currentVideoName}.jpg`}
                alt=""
              />
              {isShowLoading && <Loader />}
            </div>
            <ReactPlayer
              playsinline
              onEnded={() => {
                if (GAME_STEPS[currentStep].isLast) {
                  changePage('final');
                  sendAnalytics(
                    GAME_STEPS[currentStep].analyticAction,
                    'video',
                  );
                }
              }}
              url={[
                `./videos/${currentVideoName}.mp4`,
                `./videos/${currentVideoName}.WebM`,
              ]}
              // playbackRate={
              //   process.env.NODE_ENV === 'development' ? 2.5 : undefined
              // }
              width="100%"
              height="100%"
              ref={videoRef}
              playing={!isNeedRollPhone && !isVisibleChoice}
              onBuffer={() => setIsShowLoading(true)}
              onBufferEnd={() => setIsShowLoading(false)}
              onPlay={() => setIsShowLoading(false)}
              progressInterval={100}
              onProgress={({ playedSeconds }) => {
                if (
                  duration &&
                  playedSeconds >= duration - 1.35 &&
                  !GAME_STEPS[currentStep].isLast
                ) {
                  setIsVisibleChoice(true);
                }
              }}
              onDuration={setDuration}
              style={{ opacity: !isShowLoading ? 1 : 0 }}
            />
          </>
        )}
        {!isNeedRollPhone && !isLastVideo && (
          <Choice
            isOpen={isVisibleChoice}
            setIsVisibleChoice={setIsVisibleChoice}
          />
        )}
      </div>

      <audio ref={audioRef} src={soundSrc} loop></audio>

      {!isShowLoading && (
        <div className="hidden">
          {currentButtons?.map((button) => {
            return (
              <video
                preload="auto"
                muted
                playsInline
                key={`${button.text}_${button.nextStep}`}
              >
                <source
                  src={`./videos/${GAME_STEPS[button.nextStep].videoName}.mp4`}
                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={`./videos/${GAME_STEPS[button.nextStep].videoName}.WebM`}
                  type='video/webm;codecs="vp8, vorbis"'
                />
              </video>
            );
          })}
        </div>
      )}
    </div>
  );
};
