import cn from 'classnames';
import styles from './Intro.module.scss';
import img_speaker from './images/speaker.png';
import { Header } from '@/src/components/Header';
import { VideoCard } from '@/src/components/VideoCard';
import { Footer } from '@/src/components/Footer';

export const Intro = () => {
  return (
    <div className={styles.intro}>
      <div className={cn(styles.container, 'container')}>
        <Header />
        <div className={styles.intro_content}>
          <h1 className={cn(styles.intro_title, 'title_0')}>
            Выбери направление, в&#160;котором хочешь оказаться.
          </h1>
          <p className={cn(styles.intro_caption)}>
            И не забудь включить звук
            <img src={img_speaker} width={48} height={48} alt="" />
          </p>
          <div className={styles.intro_videos}>
            <VideoCard
              classes={styles.intro_video}
              videoName="intro_office"
              title="Офис"
              branch="office"
              line="office"
            />
            <VideoCard
              classes={styles.intro_video}
              videoName="intro_department"
              title="Отделение банка"
              branch="department"
              line="bank"
            />
          </div>
        </div>
        <Footer classes={styles.info_footer} />
      </div>
    </div>
  );
};
