import { useEffect, useMemo, useState } from 'react';
import { Home } from '../../pages/Home';
import { Preloader } from '../../components/Preloader';
import { Game } from '../../pages/Game';
import { Final } from '../Final';
import { PageContext } from './PageContext';
import styles from './App.module.scss';
import { Intro } from '@/src/pages/Intro';
import { GAME_STEPS } from '@/src/constants';
import { Loader } from '../Loader';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const App = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [page, setPage] = useState('home'); // home / preloader / intro / game / final

  const renderCurrentPage = useMemo(() => {
    switch (page) {
      case 'home':
        return <Home />;
      case 'preloader':
        return <Preloader />;
      case 'intro':
        return <Intro />;
      case 'game':
        return <Game />;
      case 'final':
        return <Final />;
      default:
        return <div className="error_render">Ошибка состояния приложения!</div>;
    }
  }, [page]);

  const switchBodyBackground = () => {
    const { body } = document;

    if (body) {
      switch (page) {
        case 'game':
          body.style.background = '#000';
          break;
        default:
          body.style.background = '#002882';
      }
    }
  };

  useEffect(() => {
    switchBodyBackground();
  }, [page]);

  let timerId: ReturnType<typeof setTimeout>;

  useEffect(() => {
    timerId = setTimeout(
      () => {
        setShowLoader(false);
      },
      process.env.NODE_ENV === 'development' ? 800 : 2400,
    );

    return () => clearTimeout(timerId);
  }, []);

  return (
    <HelmetProvider>
      <PageContext.Provider value={{ page, setPage }}>
        <Helmet>
          <link
            rel="preload"
            as="image"
            href="./posters/department/0_1_department_start.jpg"
          />
          <link
            rel="preload"
            as="image"
            href="./posters/office/0_1_office_start.jpg"
          />
        </Helmet>

        {showLoader && <Loader withBackground />}

        <div className={styles.wrapper}>{renderCurrentPage}</div>

        {(page === 'home' || page === 'preloader' || page === 'intro') && (
          <div className="hidden">
            <video preload="auto" muted playsInline>
              <source
                src={`./videos/${GAME_STEPS.department_start.videoName}.mp4`}
                type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
              />
              <source
                src={`./videos/${GAME_STEPS.department_start.videoName}.WebM`}
                type='video/webm;codecs="vp8, vorbis"'
              />
            </video>

            <video preload="auto" muted playsInline>
              <source
                src={`./videos/${GAME_STEPS.office_start.videoName}.mp4`}
                type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
              />
              <source
                src={`./videos/${GAME_STEPS.office_start.videoName}.WebM`}
                type='video/webm;codecs="vp8, vorbis"'
              />
            </video>
          </div>
        )}
      </PageContext.Provider>
    </HelmetProvider>
  );
};
