import { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './Preloader.module.scss';
import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';
import { motion } from 'framer-motion';

export const Preloader = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const [percent, setPercent] = useState(0);

  let timerId: ReturnType<typeof setTimeout> | null;

  useEffect(() => {
    const timer = () => {
      if (percent === 100) {
        timerId && clearInterval(timerId);

        setTimeout(() => {
          changePage('intro');
        }, 500);

        return;
      }

      setPercent((prev) => prev + 1);
    };

    timerId = setInterval(timer, 10);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [percent]);

  return (
    <div className={styles.preloader}>
      <div className={cn(styles.content)}>
        <div className={styles.percent}>{percent}%</div>
        <div className={cn(styles.lines)}>
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: `calc(61dvh - (36px * 1.2))`,
            }}
            className={cn(styles.line, styles.line_1)}
            transition={{
              duration: 1,
              ease: 'easeIn',
            }}
          ></motion.div>
          <motion.div
            initial={{ height: 1 }}
            animate={{
              height: `calc(100dvh - 32px - (36px * 1.22) - 22.5px)`,
            }}
            className={cn(styles.line, styles.line_2)}
            transition={{
              duration: 1,
              ease: 'easeIn',
            }}
          ></motion.div>
          <motion.div
            initial={{ height: 0 }}
            animate={{
              height: `calc(61dvh - (36px * 1.2))`,
            }}
            className={cn(styles.line, styles.line_3)}
            transition={{
              duration: 1,
              ease: 'easeIn',
            }}
          ></motion.div>
        </div>
      </div>
    </div>
  );
};
