import { RootState } from '..';

export const currentGameBranchSelector = (state: RootState) =>
  state.game.branch;

export const currentGameAnswersSelector = (state: RootState) =>
  state.game.answers;

export const currentGameSkillsSelector = (state: RootState) =>
  state.game.skills;

export const currentGameCurrentStepSelector = (state: RootState) =>
  state.game.currentStep;
