import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TBranch = 'department' | 'office';

interface IState {
  branch: TBranch | null;
  answers: string[];
  skills: string[];
  currentStep: string;
}

const initialState: IState = {
  branch: null,
  answers: [],
  skills: [],
  currentStep: '',
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setBranch(state, action: PayloadAction<TBranch | null>) {
      state.branch = action.payload;
    },
    addAnswer(state, action: PayloadAction<string>) {
      state.answers.push(action.payload);
    },
    addSkill(state, action: PayloadAction<string>) {
      state.skills.push(action.payload);
    },
    resetGame(state) {
      state.branch = null;
      state.answers = [];
      state.skills = [];
    },
    setCurrentStep(state, action: PayloadAction<string>) {
      state.currentStep = action.payload;
    },
  },
});

export const { setBranch, addAnswer, addSkill, resetGame, setCurrentStep } =
  gameSlice.actions;

export default gameSlice;
