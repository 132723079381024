export default function sendEventToCounters(params) {
  const { action, label } = params;

  // eslint-disable-next-line no-console
  // console.log('GOAL: ', `vtb2024_${action}_${label}`, params);

  if (typeof ym !== 'undefined') {
    // eslint-disable-next-line no-undef
    ym(90522445, 'reachGoal', `vtb2024_${action}_${label}`);
  }
}
