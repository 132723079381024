import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import useLandscapeOrientation from '@/src/hooks/useOrientation';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import { GAME_STEPS, TStepButton } from '@/src/constants';
// import { changeVideoTab } from '@/src/utils/changeVideoTab';
import { useAppDispatch, useAppSelector } from '@/src/hooks';
import {
  addAnswer,
  addSkill,
  setCurrentStep,
} from '@/src/store/slices/gameSlice';
import sendEventToCounters from '@/src/counterEvents';
import styles from './Choice.module.scss';
import { currentGameCurrentStepSelector } from '@/src/store/selectors/game';

type TProps = {
  isOpen: boolean;
  setIsVisibleChoice: Dispatch<SetStateAction<boolean>>;
};

export const Choice = ({ isOpen, setIsVisibleChoice }: TProps) => {
  const isLandscapeOrientation = useLandscapeOrientation();
  const [isVertical, setIsVertical] = useState(false);
  const [isVisibleButtons, setIsVisibleButtons] = useState(false);

  const dispatch = useAppDispatch();

  const buttonLeftRef = useRef(null);
  const buttonRightRef = useRef(null);
  const buttonsContainer = useRef(null);

  const currentStep = useAppSelector(currentGameCurrentStepSelector);

  const data = GAME_STEPS[currentStep];

  const { buttons, analyticAction } = data;

  const sendAnalytics = (label: string, action?: string) => {
    sendEventToCounters({ action: action ?? 'game', label });
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisibleButtons(true);
    }
  }, [isOpen]);

  const handleClickOnButton = (next: string) => {
    dispatch(setCurrentStep(next));

    setIsVisibleChoice(false);
    setIsVisibleButtons(false);

    // const nextVideo = GAME_STEPS[next].videoName;

    dispatch(addAnswer(next));

    // changeVideoTab(nextVideo);
  };

  useEffect(() => {
    if (!isLandscapeOrientation && window.innerWidth < 1019) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
  }, [isLandscapeOrientation]);

  const handleClickButton = (button: TStepButton) => {
    const { nextStep, analyticLabel, skill } = button;
    handleClickOnButton(nextStep);

    if (analyticLabel) {
      sendAnalytics(analyticLabel, analyticAction);
    }

    if (skill) {
      dispatch(addSkill(skill));
    }
  };

  return (
    <>
      <div
        className={cn(styles.root, {
          [styles.root_vertical]: isVertical,
          [styles.visible]: isOpen,
        })}
      >
        <div className={cn(styles.container, 'container')}>
          {buttons && buttons.length && (
            <CSSTransition in={isOpen} timeout={200} classNames="fadeIn">
              <div
                className={cn(styles.buttons, {
                  [styles.buttons_narrow]: buttons.length === 1,
                })}
                ref={buttonsContainer}
              >
                {buttons.map((button, i) => (
                  <button
                    className={cn(styles.button, 'btn_choice', {
                      [styles.button_left]: i === 0,
                      [styles.button_right]: i !== 0,
                      [styles.button_hidden]: !isVisibleButtons,
                    })}
                    ref={i === 0 ? buttonLeftRef : buttonRightRef}
                    key={`${button.text}_${Date.now()}`}
                    onClick={() => handleClickButton(button)}
                  >
                    <span>{button.text}</span>
                  </button>
                ))}
              </div>
            </CSSTransition>
          )}
        </div>
      </div>
    </>
  );
};
