import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '@/src/hooks';
import { resetGame } from '@/src/store/slices/gameSlice';
import { Swiper, SwiperSlide } from 'swiper/react';
import sendEventToCounters from '@/src/counterEvents';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { Skill } from '../Skill/Skill';
import { VoteBtn } from '@/src/components/VoteBtn/VoteBtn';
import { PlayBtn } from '@/src/components/PlayBtn';
import 'swiper/css';
import styles from './Final.module.scss';
import img_office from './images/office_present.jpg';
import img_department from './images/bank_present.jpg';
import {
  currentGameBranchSelector,
  currentGameSkillsSelector,
} from '@/src/store/selectors/game';
import { RESULT_SKILLS } from '@/src/constants';
import { RotateScreen } from '../RotateScreen';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useMobileOrientation } from 'react-device-detect';

export const Final = () => {
  const [isNeedRollPhone, setIsNeedRollPhone] = useState(false);
  const { isLandscape } = useMobileOrientation();

  useEffect(() => {
    if (isLandscape && isMobile) {
      setIsNeedRollPhone(true);
    } else {
      setIsNeedRollPhone(false);
    }
  }, [isLandscape, isMobile]);

  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;
  const branch = useAppSelector(currentGameBranchSelector) || 'department';
  const selectedSkills = useAppSelector(currentGameSkillsSelector);
  const dispatch = useAppDispatch();

  const skills = Object.entries(RESULT_SKILLS)
    .filter(([key]) => selectedSkills.includes(key))
    .map(([_key, value]) => value);

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'results', label });
  };

  const handleGameRetry = () => {
    dispatch(resetGame());
    changePage('preloader');
    sendAnalytics('playAgain');
  };

  return (
    <div className={styles.final}>
      {isNeedRollPhone && <RotateScreen isOpen={isNeedRollPhone} />}
      <div className={cn(styles.container, 'container')}>
        <Header classes="header_final" />
        <div className={cn(styles.media)}>
          <div className={styles.media_blur}></div>
          <div className={cn(styles.media_img, 'blur_edges')}>
            <img
              src={branch === 'office' ? img_office : img_department}
              width={1556}
              height={881}
              alt=""
            />
          </div>
        </div>
        <div className={cn(styles.main)}>
          <div className={cn(styles.content)}>
            <h1 className={cn(styles.title)}>
              Укажи свою суперсилу в&#160;резюме!
            </h1>
            <div className={cn(styles.skills)}>
              {!isMobile ? (
                <div className={cn(styles.list)}>
                  {skills.map(({ title, text }, i) => {
                    return (
                      <Skill
                        key={i}
                        classes={styles.skill}
                        title={title}
                        text={text}
                      />
                    );
                  })}
                </div>
              ) : (
                <Swiper
                  slidesPerView={'auto'}
                  slideToClickedSlide={true}
                  spaceBetween={20}
                  observer
                  observeParents
                >
                  {skills.map(({ title, text }, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <Skill key={i} title={title} text={text} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
          <div className={cn(styles.btns, 'flex')}>
            <VoteBtn
              classes={cn(styles.btn_support, 'btn_bg', 'btn_heart')}
              onClick={() => sendAnalytics('supportVTB')}
            />
            <PlayBtn
              classes={cn(styles.btn_play, 'btn', 'btn_car')}
              text="Играть ещё раз"
              onClick={handleGameRetry}
            />
          </div>
        </div>
        <Footer classes={styles.footer} />
      </div>
    </div>
  );
};
