import cn from 'classnames';
import icon_car from './images/icon_car.png';

type TProps = {
  text?: string;
  line2?: string;
  classes?: string;
  onClick: () => void;
};

export const PlayBtn = ({
  text,
  //  = '',
  // line2 = '',
  classes,
  onClick,
}: TProps) => {
  // const sendAnalytics = () => {
  // sendEventToCounters({ action: analytics_action, label: analytics_label });
  // };

  return (
    <button className={cn(classes, 'btn_play')} onClick={onClick}>
      <div className="btn_play__icon">
        <img src={icon_car} width={95} height={61} alt="" />
      </div>
      {text && <p>{text}</p>}
      {!text && (
        <p>
          Загляни в&#160;мир будущего
          <span> </span>
          <br />
          с&#160;машиной времени&#160;ВТБ
        </p>
      )}
    </button>
  );
};
