import cn from 'classnames';
import styles from './Loader.module.scss';

type TProps = {
  withBackground?: boolean;
};

export const Loader = ({ withBackground = false }: TProps) => {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper_bg]: withBackground,
      })}
    >
      <div className={styles.loader}></div>
    </div>
  );
};
