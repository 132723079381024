import cn from 'classnames';
// import styles from './VoteBtn.module.scss';
import { VOTE_HREF } from '@/src/constants';
import icon_heart from './images/icon_heart.png';

type TProps = {
  text?: string;
  classes?: string;
  onClick: () => void;
};

export const VoteBtn = ({
  text = 'Поддержи ВТБ в\u00A0Рейтинге\u00A0hh.ru',
  classes,
  onClick,
}: TProps) => {
  return (
    <a
      className={cn(classes, 'btn')}
      href={VOTE_HREF}
      target="_blank"
      rel="noreferrer"
      onClick={onClick}
    >
      <img src={icon_heart} width={68} height={68} alt="" />
      {text}
    </a>
  );
};
