import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import styles from './RotateScreen.module.scss';
import logo from '../../images/logo.svg';
import rotate_icon from './images/rotate_icon.png';

type TProps = {
  isOpen: boolean;
};

export const RotateScreen = ({ isOpen }: TProps) => {
  return (
    <CSSTransition in={isOpen} timeout={200} classNames="fadeIn">
      <div className={styles.rotate}>
        <div className={styles.logo}>
          <img src={logo} width={86} height={29} alt="Логотип ВТБ" />
        </div>
        <div className={cn(styles.content)}>
          <div className={styles.rotate_icon}>
            <img src={rotate_icon} width={172} height={172} alt="Логотип ВТБ" />
          </div>
          <p className={styles.text}>
            Для удобства использования переверните телефон
          </p>
        </div>
      </div>
    </CSSTransition>
  );
};
